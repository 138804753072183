@use 'config' as c;
@use 'mixin' as m;

/*===================================
header
===================================*/
header {
    .primary_header {
        .container {
            .row {
                .title {
                    padding: 10px 0 5px 10px;
                    a {
                        width:100px;
                        object {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
/*===================================
slide-wrap
===================================*/
.slide-wrap {
    width:100%;
    padding-bottom: 60px;
    .mv-sld {
        width:100%;
        .sld {
            width: 100%;
            >img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 100 / 58;
            }
            picture {
                >img {
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 100 / 58;
                }
            }
        }
    }
    @include m.mq(sm) {
        padding-bottom: 20px;
        .mv-sld {
            .sld {
                >img {
                    aspect-ratio: 100 / 78;
                }
                picture {
                    >img {
                        aspect-ratio: 100 / 78;
                    }
                }
            }
        }
    }
}
/*===================================
slick-dots
===================================*/
.slick-dots {
    bottom: -24px;
    li {
      width:10px;
      height: 10px;
      margin: 0 5px;
      &.slick-active {
        button {
            background: #fbda95;
        }
      }
      button {
          width:8px;
          height: 8px;
          &::before {
              width:10px;
              height: 10px;
          }
      }
    }
}

/*===================================
slide-wrap
===================================*/
.slide-wrap {
    .mv-sld {
        .sld {
            position: relative;
            .over {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top:0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .nm-flex {
                    display: flex;
                }
                .fig-flex {
                    display: flex;
                    .pic {
                        width:16vw;
                    }
                }
                .rights {
                    width:5vw;
                    height: 4.2vw;
                    position: absolute;
                    right: 30px;
                    bottom: 30px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    @include m.mq(sm) {
        .mv-sld {
            .sld {
                .over {
                    .fig-flex {
                        justify-content: flex-start;
                        .pic {
                            width:36vw;
                            margin-right: 0;
                        }
                        .txt-area {
                            width:60vw;
                        }
                    }
                    .rights {
                        width:12vw;
                        height: 10vw;
                        right: 15px;
                        bottom: 15px;
                    }
                }
            }
        }
    }
}
/*===================================
slide-wrap mv-sld over
===================================*/
.mv-sld {
    .sld {
        &.grn {
            .over {
                .nm-flex {
                    .cc {
                        color:#fff;
                        -webkit-text-stroke: 1.6px #03289f;
                        font-weight: 900;
                    }
                    .read {
                        color:#fff;
                        -webkit-text-stroke: 1.6px #03289f;
                        font-weight: 900;
                    }
                }
                .fig-flex {
                    .txt-area {
                        .state {
                            color:#fff;
                            -webkit-text-stroke: 1.6px #03289f;
                            font-weight: 900;
                        }
                    }
                }
            }
        }
        &.or {
            .over {
                .nm-flex {
                    .cc {
                        color:#fff;
                        -webkit-text-stroke: 1.6px #de3900;
                        font-weight: 900;
                    }
                    .read {
                        color:#fff;
                        -webkit-text-stroke: 1.6px #de3900;
                        font-weight: 900;
                    }
                }
                .fig-flex {
                    .txt-area {
                        .state {
                            color:#fff;
                            -webkit-text-stroke: 1.6px #de3900;
                            font-weight: 900;
                        }
                    }
                }
            }
        }
        &.pp {
            .over {
                .nm-flex {
                    .cc {
                        color:#680264;
                    }
                    .read {
                        color:#680264;
                    }
                }
                .fig-flex {
                    .txt-area {
                        .state {
                            color:#680264;
                        }
                    }
                }
            }
        }
        .over {
            font-weight: 900;
            font-family: 'M PLUS 1p', sans-serif;
            padding-top: 1.25vw;
            .nm-flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                .cc {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    span {
                        font-weight: 900;
                        font-size: 5.5vw;
                        &.given {
                            margin-left: 2vw;
                        }
                    }
                }
                .read {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    margin-left: 0.5vw;
                    font-size: 2vw;
                    span {
                        &.given {
                            margin-left: 1.25vw;
                        }
                    }
                }
            }
            .fig-flex {
                display: flex;
                justify-content: center;
                padding-right: 2vw;
                margin-top: 1.25vw;
                .pic {
                    margin-right: 10px;
                    figure {
                        display: block;
                        width: 100%;
                        padding-bottom: 160%;
                        height: 0;
                        position: relative;
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: auto;
                            position: absolute;
                            min-height: 100%;
                            min-width: 100%;
                        }
                    }
                }
                .txt-area {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 1.5vw;
                    .lines {
                        color:#1a1a1a;
                        font-size: 2.5vw;
                        line-height: 1.28;
                        margin: 0;
                        .sm-only {
                            display: none;
                        }
                        .lft {
                            text-align: left;
                            margin: 0.1em 0;
                        }
                        .cntr {
                            text-align: center;
                            margin: 0.1em 0;
                            br {
                                display: none;
                            }
                        }
                        .rht {
                            text-align: right;
                            margin: 0.1em 0;
                        }
                    }
                    .state {
                        font-size: 3.75vw;
                        margin: 0;
                        margin-top: 0.07vw;
                        text-align: center;
                        line-height: 1.28;
                    }
                }
            }
        }
    }
    @include m.mq(sm) {
        .sld {
            &.grn {
                .over {
                    .nm-flex {
                        .cc {
                            -webkit-text-stroke: 1px #03289f;
                        }
                        .read {
                            -webkit-text-stroke: 1px #03289f;
                        }
                    }
                    .fig-flex {
                        .txt-area {
                            .state {
                                -webkit-text-stroke: 1px #03289f;
                            }
                        }
                    }
                }
            }
            &.or {
                .over {
                    .nm-flex {
                        .cc {
                            -webkit-text-stroke: 1px #de3900;
                        }
                        .read {
                            -webkit-text-stroke: 1px #de3900;
                        }
                    }
                    .fig-flex {
                        .txt-area {
                            .state {
                                -webkit-text-stroke: 1px #de3900;
                            }
                        }
                    }
                }
            }
            .over {
                padding-top: 3.2vw;
                .nm-flex {
                    .cc {
                        span {
                            font-size: 10vw;
                            &.given {
                                margin-left: 2vw;
                            }
                        }
                    }
                    .read {
                        margin-left: 1vw;
                        font-size: 4vw;
                        span {
                            &.given {
                                margin-left: 1.25vw;
                            }
                        }
                    }
                }
                .fig-flex {
                    padding-right: 2vw;
                    margin-top: 1.25vw;
                    .pic {
                        margin-right: 10px;
                        figure {
                            display: block;
                            width: 100%;
                            padding-bottom: 160%;
                        }
                    }
                    .txt-area {
                        margin-top: 2vw;
                        .lines {
                            font-size: 6vw;
                            .sm-only {
                                display: block;
                            }
                            .lft {
                                margin: 0.1em 0;
                            }
                            .cntr {
                                margin: 0.1em 0;
                                br {
                                    display: block;
                                }
                            }
                            .rht {
                                margin: 0.1em 0;
                            }
                        }
                        .state {
                            font-size: 6vw;
                            margin-top: 0.07vw;
                        }
                    }
                }
            }
        }
    }
}





@include m.mq(md) {}
@include m.mq(sm) {}
/*===================================
fin
===================================*/