/*===================================
breakpoints
===================================*/
$breakpoints: (
  'xxs':      'screen and (max-width:  374px)',
  'xs':       'screen and (max-width:  500px)',
  'sm':       'screen and (max-width:  767px)',
  'md':       'screen and (max-width:  1023px)',
  'lg':      'screen and (max-width: 1279px)',
  'xl':     'screen and (max-width: 1365px)',
  'xxl':    'screen and (max-width: 1439px)',
  'xxxl':    'screen and (max-width: 1600px)',
) !default;

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
/*===================================
長いテキストに省略記号
===================================*/
@mixin ellipsis($rowCount: 1) {
  @if $rowCount <1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $rowCount;
    -webkit-box-orient: vertical;
  }
}
/*===================================
fullsize
===================================*/
@mixin fullsize {
  margin: 0 calc(50% - 50vw);
	width: 100vw;
}
